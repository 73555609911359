@import '../../styles/styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css');

/*
Default Style
============================*/

// MEDIA QUERY MANAGER

/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components
*/

body {
    background: url(/images/backgroundmesh.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-family: 'Dosis', sans-serif;
    // background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

a {
    text-decoration: none;
    color: black;
}


/*
Default Btn Area Style
======================================================*/



.default-btn {
    font-size: 15px;
    font-weight: 600;
    width: 50vw;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 20px 30px;
    line-height: 1;
    transition: $transition;
    z-index: 1;
    text-transform: capitalize;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: -40px;
        width: 10px;
        height: 100px;
        background-color: #ffffff;
        opacity: 0.5;
        transition: $transition;
        transform: rotate(45deg);
    }
    &::after {
        content: "";
        position: absolute;
        top: -20px;
        right: -40px;
        width: 10px;
        height: 100px;
        background-color: #ffffff;
        opacity: 0.5;
        transition: $transition;
        transform: rotate(45deg);
    }
    &.active {
        background-color: $white-color;
        color: $main-color;
        &:hover {
            color: $white-color;
            background-color: $main-color;
        }
    }
    &:hover {
        color: $white-color;
        transition: $transition;
        &::before {
            left: 200px;
        }
        &::after {
            right: 200px;
        }
    }
}

.default-btn>a {
    text-decoration: none;
    color: white;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .default-btn{
        width: 40vw;
    }
}
@media only screen and (min-width: 767px) {
    .default-btn{
        width: 25vw;
    }
}

.center-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

// BANNER 
.banner-container {
    display: flex;
    margin-top: 5rem;
    width: 100vw;
    align-items: center;
    justify-content: center;
    // margin-left: auto;
}

.container-social {
    display: flex;
    justify-content: space-evenly;
}


/* Specific colors of hoversocial links when hovered */

.links-social :hover {
    color: #000 !important;
}

.links-social .github-link:hover {
    color: #000 !important;
    z-index: 1;
}

.links-social .linkedin-link:hover {
    color: #007bb5 !important;
    z-index: 1;
}

.links-social .twitter-link:hover {
    color: #55acee !important;
}

.links-social .email-link:hover {
    color: #dd4b39 !important;
}

//   Icon > twitter-link:hover{ 
//     color: #55acee;
//   }
// PHONE
@media only screen and (max-width: 767px) {
    .main-banner-area {
        // background-image: linear-gradient(#00095e, #000638);
        background: url(/images/grabient.png);
        padding-top: 100px;
        padding-bottom: 120px;
        position: relative;
        z-index: 1;
    }
    .banner-main-img {
        display: block;
        height: 107vh;
        margin-top: -220px;
        margin-bottom: 2vh;
    }
    .about-img-full {
        display: none;
    }
}

// FOR TABLETS
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-img-mobile {
        display: none;
    }
    p {
        font-size: 14px;
    }
    h3 {
        font-size: 20px;
    }
    .container-fluid {
        max-width: 720px;
        display: flex;
        flex-direction: row;
    }
    .main-banner-area {
        padding-bottom: 20vh;
        position: relative;
        z-index: 1;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-img-mobile {
        display: none;
    }
    .main-banner-area {
        margin-top: -10vh;
        padding-bottom: 20vh;
        position: relative;
        z-index: 1;
        .banner-text {
            h1 {
                font-size: 40px;
                margin-bottom: 30px;
            }
            p {
                font-size: 15px;
            }
        }
    }
    .about-content {
        h2 {
            font-size: 30px;
        }
    }
    .choose-title {
        h2 {
            font-size: 30px;
        }
    }
    .banner-main-img {
        display: block;
        height: 107vh;
        margin-top: -20px;
        margin-bottom: 2vh;
    }
}

@media only screen and (min-width: 1200px) {
    .about-img-mobile {
        display: none;
    }
    .main-banner-area {
        margin-top: -10vh;
        background-image: linear-gradient(#00095e, #000638);
        background: url(/images/grabient.png);
        position: relative;
        z-index: 1;
        padding-bottom: 12rem;
        margin-bottom: -5rem;
    }
    .links-social {
        margin-top: -27vh;
    }
    .main-banner-area {
        &.main-banner-area-one {
            padding-top: 250px;
            padding-bottom: 350px;
            padding-right: -100px;
            .banner-text {
                top: 40px;
            }
            .banner-img {
                img {
                    &:nth-child(1) {
                        bottom: -445px;
                    }
                    &:nth-child(2) {
                        bottom: -850px;
                        left: 130px;
                    }
                    &:nth-child(3) {
                        left: 210px;
                    }
                    &:nth-child(4) {
                        right: 0px;
                    }
                }
            }
        }
    }
    .banner-main-img {
        display: block;
        height: 107vh;
        margin-top: -20px;
        margin-bottom: 2vh;
    }
}

.main-banner-area {
    display: flex;
    background: url(/images/grabient.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // position: relative;
    z-index: 1;
    margin: auto;
    .banner-text {
        position: relative;
        max-width: 640px;
        padding: auto;
        h1 {
            background-image: linear-gradient(90deg, rgb(250, 191, 191) 0%, rgb(243, 211, 210) 56%, rgb(250, 215, 191) 100%);
            font-weight: 700;
            color: transparent;
            -webkit-background-clip: text;
            // animation: fade 1.5s infinite;
            animation: move 1.5s infinite;
        }
        p {
            color: $white-color;
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
    .container-fluid {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}

.social-links {
    display: flex;
    margin-top: 2vh;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    max-height: 100vw;
    flex-direction: row;
}

.social-links ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    width: 100vw;
    // border: 2px solid white;
    padding-left: 0vw;
}

.social-links ul li a {
    width: 12vw;
    height: 8vh;
    color: white;
    font-size: 2em;
    text-decoration: none;
    line-height: 75px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    float: left;
    transition: all 0.5s ease;
}
// hover

.social-links ul li:hover.social-icons {
    // box-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
    filter: drop-shadow(2px 4px 8px #585858);
}

.social-icons a:hover {
    color: rgba(94,114,235,1);
}

@keyframes move {
    100% {
        background-image: linear-gradient(200deg, rgb(250, 250, 250) 0%, rgb(253, 216, 215) 56%, rgb(255, 253, 251) 100%);
    }
}