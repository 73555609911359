@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@300;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
Default Style
============================*/
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components
*/
body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  color: #4d4d4d;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #212121;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
}

h3 {
  font-size: 22px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/*
Preloader Area Style
======================================================*/
.preloader-deactivate {
  display: none;
}

.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #04063c;
  top: 0;
  left: 0;
  z-index: 99999;
}

.spinner {
  height: 40px;
  font-size: 10px;
  text-align: center;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
/*
Default Style
============================*/
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components
*/
body {
  background: url(/images/backgroundmesh.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: "Dosis", sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

/*
Default Btn Area Style
======================================================*/
.default-btn {
  font-size: 15px;
  font-weight: 600;
  width: 50vw;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 20px 30px;
  line-height: 1;
  transition: all 0.6s ease;
  z-index: 1;
  text-transform: capitalize;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.default-btn::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -40px;
  width: 10px;
  height: 100px;
  background-color: #ffffff;
  opacity: 0.5;
  transition: all 0.6s ease;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.default-btn::after {
  content: "";
  position: absolute;
  top: -20px;
  right: -40px;
  width: 10px;
  height: 100px;
  background-color: #ffffff;
  opacity: 0.5;
  transition: all 0.6s ease;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.default-btn.active {
  background-color: #ffffff;
  color: white;
}
.default-btn.active:hover {
  color: #ffffff;
  background-color: white;
}
.default-btn:hover {
  color: #ffffff;
  transition: all 0.6s ease;
}
.default-btn:hover::before {
  left: 200px;
}
.default-btn:hover::after {
  right: 200px;
}

.default-btn > a {
  text-decoration: none;
  color: white;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .default-btn {
    width: 40vw;
  }
}
@media only screen and (min-width: 767px) {
  .default-btn {
    width: 25vw;
  }
}
.center-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.banner-container {
  display: flex;
  margin-top: 5rem;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.container-social {
  display: flex;
  justify-content: space-evenly;
}

/* Specific colors of hoversocial links when hovered */
.links-social :hover {
  color: #000 !important;
}

.links-social .github-link:hover {
  color: #000 !important;
  z-index: 1;
}

.links-social .linkedin-link:hover {
  color: #007bb5 !important;
  z-index: 1;
}

.links-social .twitter-link:hover {
  color: #55acee !important;
}

.links-social .email-link:hover {
  color: #dd4b39 !important;
}

@media only screen and (max-width: 767px) {
  .main-banner-area {
    background: url(/images/grabient.png);
    padding-top: 100px;
    padding-bottom: 120px;
    position: relative;
    z-index: 1;
  }

  .banner-main-img {
    display: block;
    height: 107vh;
    margin-top: -220px;
    margin-bottom: 2vh;
  }

  .about-img-full {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img-mobile {
    display: none;
  }

  p {
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }

  .container-fluid {
    max-width: 720px;
    display: flex;
    flex-direction: row;
  }

  .main-banner-area {
    padding-bottom: 20vh;
    position: relative;
    z-index: 1;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img-mobile {
    display: none;
  }

  .main-banner-area {
    margin-top: -10vh;
    padding-bottom: 20vh;
    position: relative;
    z-index: 1;
  }
  .main-banner-area .banner-text h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .main-banner-area .banner-text p {
    font-size: 15px;
  }

  .about-content h2 {
    font-size: 30px;
  }

  .choose-title h2 {
    font-size: 30px;
  }

  .banner-main-img {
    display: block;
    height: 107vh;
    margin-top: -20px;
    margin-bottom: 2vh;
  }
}
@media only screen and (min-width: 1200px) {
  .about-img-mobile {
    display: none;
  }

  .main-banner-area {
    margin-top: -10vh;
    background-image: linear-gradient(#00095e, #000638);
    background: url(/images/grabient.png);
    position: relative;
    z-index: 1;
    padding-bottom: 12rem;
    margin-bottom: -5rem;
  }

  .links-social {
    margin-top: -27vh;
  }

  .main-banner-area.main-banner-area-one {
    padding-top: 250px;
    padding-bottom: 350px;
    padding-right: -100px;
  }
  .main-banner-area.main-banner-area-one .banner-text {
    top: 40px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(1) {
    bottom: -445px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(2) {
    bottom: -850px;
    left: 130px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(3) {
    left: 210px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(4) {
    right: 0px;
  }

  .banner-main-img {
    display: block;
    height: 107vh;
    margin-top: -20px;
    margin-bottom: 2vh;
  }
}
.main-banner-area {
  display: flex;
  background: url(/images/grabient.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  margin: auto;
}
.main-banner-area .banner-text {
  position: relative;
  max-width: 640px;
  padding: auto;
}
.main-banner-area .banner-text h1 {
  background-image: linear-gradient(90deg, rgb(250, 191, 191) 0%, rgb(243, 211, 210) 56%, rgb(250, 215, 191) 100%);
  font-weight: 700;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-animation: move 1.5s infinite;
          animation: move 1.5s infinite;
}
.main-banner-area .banner-text p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 40px;
}
.main-banner-area .container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.social-links {
  display: flex;
  margin-top: 2vh;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  max-height: 100vw;
  flex-direction: row;
}

.social-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100vw;
  padding-left: 0vw;
}

.social-links ul li a {
  width: 12vw;
  height: 8vh;
  color: white;
  font-size: 2em;
  text-decoration: none;
  line-height: 75px;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  float: left;
  transition: all 0.5s ease;
}

.social-links ul li:hover.social-icons {
  transition: all 0.5s ease;
  -webkit-filter: drop-shadow(2px 4px 8px #585858);
          filter: drop-shadow(2px 4px 8px #585858);
}

.social-icons a:hover {
  color: rgb(94, 114, 235);
}

@-webkit-keyframes move {
  100% {
    background-image: linear-gradient(200deg, rgb(250, 250, 250) 0%, rgb(253, 216, 215) 56%, rgb(255, 253, 251) 100%);
  }
}

@keyframes move {
  100% {
    background-image: linear-gradient(200deg, rgb(250, 250, 250) 0%, rgb(253, 216, 215) 56%, rgb(255, 253, 251) 100%);
  }
}
:root {
  --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 50%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);

  --font-family: 'Poppins', system-ui;

  --bg-gradient: linear-gradient(
    to top,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body {
  font-family: var(--font-family);
  color: var(--dark-color);
  background: var(--bg-gradient);
} */
body{
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

body {
  min-height: 100vh;
  background-color: #efefef;
  font-family: "lato", sans-serif;
  overflow-X: hidden;
}
.orb-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -10000;
}

strong {
  font-weight: 600;
}
/* need to do for mobile */
.container{
  margin: auto;
}


.text-gradient, .header-links > ul > li > a  {
  background-image: linear-gradient(
    45deg,
    hsl(var(--hue), 95%, 50%) 25%,
    hsl(var(--hue-complimentary2), 95%, 50%));
  background-image: linear-gradient(
    45deg,
    var(--base) 25%,
    var(--complimentary2)
  );
  margin: auto; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.overlay__description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
}

.overlay__btns {
  width: 100%;
  max-width: 30rem;
  display: flex;
}

.overlay__btn {
  width: 50%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: hsl(var(--hue), 95%, 98%);
  color: var(--light-color);
  background: hsl(var(--hue), 100%, 9%);
  background: var(--dark-color);
  border: none;
  border-radius: 0.5rem;
  cursor: not-allowed;
  transition: -webkit-transform 150ms ease;
  transition: transform 150ms ease;
  transition: transform 150ms ease, -webkit-transform 150ms ease;
  outline-color: hsl(var(--hue), 95%, 50%);
}

.overlay__btn--colors:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  cursor: pointer;
}

.overlay__btn--transparent {
  background: transparent;
  color: hsl(var(--hue), 100%, 9%);
  color: var(--dark-color);
  border: 2px solid hsl(var(--hue), 100%, 9%);
  border: 2px solid var(--dark-color);
  border-width: 2px;
  margin-right: 0.75rem;
  outline: none;
}

.overlay__btn-emoji {
  margin-left: 0.375rem;
}

@media only screen and (max-width: 1140px) {
  .overlay {
    padding: 8rem 4rem;
  }
}

@media only screen and (max-width: 840px) {
  body {
    padding: 1.5rem;
  }

  .overlay {
    padding: 4rem;
    height: auto;
  }

  .overlay__title {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .overlay__description {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .overlay {
    padding: 1.5rem;
  }
  .container-fluid {
    max-width: 540px;
}

  .overlay__btns {
    flex-wrap: wrap;
  }

  .overlay__btn {
    width: 100%;
    font-size: 0.75rem;
    margin-right: 0;
  }

  .overlay__btn:first-child {
    margin-bottom: 1rem;
  }
}

.button-view {
    width: 20vw;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 13px 20px;
    background-image: linear-gradient(
      45deg,
      hsl(var(--hue), 95%, 50%) 25%,
      hsl(var(--hue-complimentary2), 95%, 50%));
    background-image: linear-gradient(
      45deg,
      var(--base) 25%,
      var(--complimentary2)
    );
}
/*
Default Style
============================*/
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components
*/
body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  color: #4d4d4d;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #212121;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
}

h3 {
  font-size: 22px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/*
Preloader Area Style
======================================================*/
.preloader-deactivate {
  display: none;
}

.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #04063c;
  top: 0;
  left: 0;
  z-index: 99999;
}

.spinner {
  height: 40px;
  font-size: 10px;
  text-align: center;
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
:root {
  --bg-gradient: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
}

footer {
  display: flex;
  width: 400px;
  margin: auto;
  text-align: center;
  background: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
  background: var(--bg-gradient);
  opacity: 0.8;
  margin-bottom: 0;
  height: 30vh;
  width: 100%;
  text-align: center;
  padding: 5px 0 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

footer a {
  color: #fff;
}

.mesh-footer {
  background: url(/images/mesh-profile.png);
  background-size: cover;
  z-index: -1;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
}

.gradient-connect {
  font-family: "Pacifico", cursive;
  background-image: linear-gradient(90deg, rgb(94, 114, 235) 0%, rgb(255, 145, 144) 56%, rgb(254, 193, 149) 100%);
  color: transparent;
  -webkit-background-clip: text;
  -webkit-animation: move 1s infinite;
          animation: move 1s infinite;
}

@-webkit-keyframes move {
  50% {
    background-image: linear-gradient(262deg, rgb(94, 114, 235) 0%, rgb(255, 145, 144) 56%, rgb(254, 193, 149) 100%);
  }
}

@keyframes move {
  50% {
    background-image: linear-gradient(262deg, rgb(94, 114, 235) 0%, rgb(255, 145, 144) 56%, rgb(254, 193, 149) 100%);
  }
}
/* Style the Un-order list by setting its list-style to none */
.wrapper ul {
  list-style: none;
  width: 90vw;
}

.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper ul li {
  width: 12vw;
  height: 8vh;
  line-height: 75px;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  float: left;
  transition: all 0.5s ease;
}

/* Style the list items inside the UL list, by setting its width, height and line-height 
  and float them to left and set its border and border-radius.
 */
/* Style the icons by setting its color and margin-top value to 20px 
to align it properly */
.wrapper ul li .fa {
  color: grey;
  transition: all 0.5s ease;
}

/* Now target the specific li classes for styling and use box-shadow effect to border and text-shadow effect
  to icons for glowing effect and use transition property for smooth transition effect. */
/*facebook*/
.wrapper ul li:hover.facebook {
  border: 5px solid #3b5998;
  box-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-facebook {
  color: #3b5998;
  text-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

/*twitter*/
.wrapper ul li:hover.twitter {
  border: 5px solid #00aced;
  box-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-twitter {
  color: #00aced;
  text-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

/* instagram */
.wrapper ul li:hover.instagram {
  border: 5px solid #bc2a8d;
  box-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-instagram {
  color: #bc2a8d;
  text-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease;
}

/* google */
.wrapper ul li:hover.google {
  border: 5px solid #dd4b39;
  box-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-google {
  color: #dd4b39;
  text-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

/* whatsapp */
.wrapper ul li:hover.whatsapp {
  border: 5px solid #4dc247;
  box-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-whatsapp {
  color: #4dc247;
  text-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}

@media screen and (min-width: 750px) {
  .wrapper {
    width: 100vw;
  }

  .wrapper ul li {
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid grey;
    float: left;
    transition: all 0.5s ease;
  }

  .copy-right {
    display: flex;
    flex-direction: column;
  }
}
.box {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  height: 310px;
  overflow-y: auto;
}
.box .icon {
  color: transparent;
  font-size: 4rem;
  margin-bottom: 2rem;
  background-image: linear-gradient(to right, rgba(255, 75, 31, 0.7), rgba(255, 144, 104, 0.7));
}
.box h2 {
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
  font-family: "Oswald", sans-serif;
}
.box p {
  letter-spacing: 1.2px;
}
.box:hover {
  -webkit-transform: translatey(-7px) scale(1.03);
          transform: translatey(-7px) scale(1.03);
}

h1 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 100;
  margin-bottom: 32px;
}

.cards {
  display: grid;
  grid-gap: 32px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
}

.card {
  width: 200px;
  height: 300px;
  margin: auto;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  border: 2px solid #928c8c;
  background-color: rgba(255, 255, 255, 0.75);
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.75) 100%);
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 10px;
  border-image: linear-gradient(220deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.75) 100%);
}

.card:hover {
  -webkit-transform: scale(1.008) translateY(-5px);
          transform: scale(1.008) translateY(-5px);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.card-desc {
  line-height: 1.6;
  color: #636b6f;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}

.card-subtitle {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
}

.card-content {
  text-align: left;
  padding-left: 10px;
}

.card-jobtitle {
  text-transform: uppercase;
  color: grey;
  letter-spacing: 1px;
  font-size: 14px;
  margin-top: -10px;
}

.image-voyager {
  height: 220px;
  width: 100%;
  background-size: cover;
  background-position: 50% 70%;
}

/* to center an image */
/* margin-left: auto;
margin-right: auto; */
@media (min-width: 660px) {
  .image-voyager {
    height: 220px;
    width: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50% 70%;
  }

  .card {
    width: 340px;
    margin: auto;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    border: 2px solid #928c8c;
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.75);
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.75) 100%);
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    border-image: linear-gradient(220deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.75) 100%);
  }
}
/* BUTTONS */
.card-buttons {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-top: auto;
  /* position: sticky; */
  bottom: 0;
  left: 0;
}

button {
  flex: 1 1 auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: 0;
  font-size: 13px;
  border: 0;
  padding: 15px 5px;
  cursor: pointer;
  color: #5c5c6d;
  transition: 0.3s;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  outline: 0;
  border-bottom: 3px solid transparent;
}

button:hover {
  color: #2b2c48;
  border-bottom: 3px solid #8a84ff;
  background: linear-gradient(to bottom, rgba(127, 199, 231, 0) 0%, rgba(207, 204, 255, 0.2) 44%, rgba(211, 226, 255, 0.4) 100%);
}
body {
  min-height: 100vh;
  font-family: "lato", sans-serif;
  overflow-X: hidden;
}

.view-all-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.product-image img {
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 300px;
}

.section-head {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  -webkit-transform: skew(0deg, 6deg);
          transform: skew(0deg, 6deg);
  padding-top: 6vh;
}

.section-head h4 {
  position: relative;
  padding: 0;
  color: #f91942;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.section-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}

.parent {
  border: 1px solid rgba(255, 255, 255, 0.18);
  -webkit-transform: skewY(-5deg);
          transform: skewY(-5deg);
  padding-bottom: -50px;
  margin-top: 100px;
  width: 100vw;
  height: 80vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
}

.project-container {
  -webkit-transform: skew(0deg, 6deg);
          transform: skew(0deg, 6deg);
}

.mesh-background {
  padding-top: 100px;
  padding-bottom: 50px;
  margin-top: -25vh;
  background: url(/images/mesh-profile.png);
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.parent > .mesh-background {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.outer-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3rem;
  max-width: 93.5rem;
  -webkit-transform: skew(0deg, 6deg);
          transform: skew(0deg, 6deg);
}
.banner-btn {
  display: flex;
  flex-direction: row;
}

.card-container {
  margin: auto;
}

.about-container {
  margin: 0 auto;
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
  height: 7vh;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.card-container,
.about-container {
  background-image: #b8dbf9;
  background-image: radial-gradient(at 70% 50%, hsl(5deg, 88%, 79%) 0, transparent 50%), radial-gradient(at 30% 85%, rgb(245, 202, 241) 0, transparent 50%), radial-gradient(at 85% 90%, hsl(4deg, 88%, 79%) 0, transparent 40%), radial-gradient(at 30% 30%, rgb(139, 195, 235) 0, transparent 59%), radial-gradient(at 10% 80%, hsl(168deg, 29%, 87%) 0, transparent 90%), radial-gradient(at 25% 5%, hsl(208deg, 85%, 85%) 0, transparent 50%), radial-gradient(at 85% 20%, rgb(178, 122, 221) 0, transparent 50%);
}

#btn-display {
  background-color: hsl(274deg, 78%, 85%);
  background-image: radial-gradient(at 40% 20%, hsl(28deg, 100%, 74%) 0px, transparent 50%), radial-gradient(at 0% 50%, hsl(216deg, 88%, 80%) 0px, transparent 50%), radial-gradient(at 80% 50%, hsl(340deg, 100%, 76%) 0px, transparent 50%), radial-gradient(at 0% 100%, hsl(271deg, 69%, 70%) 0px, transparent 50%), radial-gradient(at 0% 0%, hsl(343deg, 100%, 76%) 0px, transparent 50%);
}

.overlay {
  width: 100%;
  max-width: 1140px;
  max-height: 640px;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.375);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.skills-banner {
  background-color: #2196F3;
}

.card-container {
  margin: 0 auto;
  width: 20vw;
  height: 7vh;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.skills-head {
  display: flex;
  flex-direction: column;
  padding-top: 6vh;
  margin: auto;
}

.skills-head h4 {
  position: relative;
  padding: 0;
  color: black;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.skills-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.skills h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}

@media only screen and (max-width: 450px) {
  .container {
    margin-left: -31px;
  }

  .skills-head {
    margin-left: 57px;
  }

  .card-container {
    margin: 0 auto;
    width: 30vw;
    height: 7vh;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
  }

  .row {
    margin: 0 -4vh;
  }
}
@media only screen and (max-width: 599px) {
  .container {
    margin-left: -31px;
  }

  .skills-head {
    margin-left: 57px;
  }

  .card-container {
    margin: 0 auto;
    width: 25vw;
    height: 7vh;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
  }

  .row {
    margin: 1 -6vh;
  }

  .col-lg-4,
.col-sm-6 {
    width: 21vw;
    margin: auto;
  }

  .overlay {
    width: 100%;
    max-width: 90vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.375);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }
}
@media only screen and (max-width: 1139px) {
  .overlay {
    width: 100%;
    max-width: 90vw;
    max-height: 100vh;
    padding: 2rem 6rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.375);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }

  .section-head {
    margin-bottom: -10px;
  }
}
.skewed-bg2 {
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  height: 450px;
  width: 101%;
  -webkit-transform: skew(0deg, -6deg);
          transform: skew(0deg, -6deg);
  margin-top: -50px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 100px;
  margin-right: 200px;
}

.skewed-text {
  -webkit-transform: skew(0deg, 6deg);
          transform: skew(0deg, 6deg);
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.space {
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.about-img {
  margin-bottom: 30px;
  text-align: center;
  margin-right: 5rem;
  margin-left: 5rem;
}

.about-img img {
  margin: auto;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.375);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
}

.about-headline {
  font-weight: 200;
}

@media only screen and (max-width: 992px) {
  .skewed-bg2 {
    height: 590px;
    width: 102%;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 993) {
  .skewed-bg2 {
    height: 600px;
    width: 102%;
    padding-right: 20px;
  }
}
.nav-header {
  position: -webkit-sticky;
  position: sticky;
  position: fixed;
  right: 0;
  z-index: 600;
  padding: 10px;
}

#sideNav > h2 > a:hover {
  transition: all 0.5s ease;
  -webkit-filter: drop-shadow(2px 4px 8px #585858);
          filter: drop-shadow(2px 4px 8px #585858);
}

#sideNav > h2 > a:hover {
  color: rgb(94, 114, 235);
}

.hamburger {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

/* General styles for the lines */
.hamburger .line {
  display: block;
  width: 40px;
  height: 3px;
  background-color: #D9AFD9;
  background-image: linear-gradient(20deg, #D9AFD9 9%, #97D9E1 55%);
  margin-block: 10px;
  /*Adds separation to the bottom and the bottom*/
  border-radius: 4px;
  transition: opacity 0.25s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.25s;
  transition: transform 0.5s, opacity 0.25s, -webkit-transform 0.5s;
}

/* Selecting the lines individually to add different effects when the active class is added to the hamburger menu.*/
.hamburger.active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
          transform: translateY(13px) rotate(45deg);
  /*Moves down this line 13px and rotates it 45 degrees to create the X*/
}

.hamburger.active .line:nth-child(2) {
  opacity: 0;
  /*Hides this line.*/
}

.hamburger.active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
          transform: translateY(-13px) rotate(-45deg);
  /*Moves up this line 13px and rotates it -45 degrees to create the X*/
}

.hamburger.active {
  position: fixed;
  right: 0;
  color: purple;
}

.hamburger.active .line {
  color: purple;
}

.container-sidenav {
  display: flex;
  justify-content: space-between;
}

#sideNav {
  display: flex;
  flex-direction: column;
  padding: 10rem;
  background-color: beige;
  width: 100%;
  height: 100vh;
  transition: 0.45s ease;
  padding: 8rem 6rem;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/*
Preloader Area Style
======================================================*/
.preloader-deactivate {
    display: none;
  }
  
  .preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #04063c;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  
  .spinner {
    height: 40px;
    font-size: 10px;
    text-align: center;
  }
  
  .spinner > div {
    background-color: #fff;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
