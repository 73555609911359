.skewed-bg2{
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  // background: url(/images/backgroundmesh.png);
  height:450px;
  width: 101%;
  transform:skew(0deg, -6deg);
  margin-top:-50px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 100px;
  margin-right: 200px;
}
.skewed-text{
  transform:skew(0deg, 6deg);
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.space {
  margin-top: 10rem;
  margin-bottom: 5rem;
}


// ABOUT ME
.about-img {
  margin-bottom: 30px;
  text-align: center;
  margin-right: 5rem;
  margin-left: 5rem;
}
.about-img img{
  margin: auto;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.375);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
}
.about-headline { 
  font-weight: 200;
}
// FOR LAPTOPS
@media only screen and (max-width : 992px) {
  .skewed-bg2{
    height: 590px;
    width: 102%;
    padding-right: 20px;
  }
}

@media only screen and (min-width : 993) {
  .skewed-bg2{
    height: 600px;
    width: 102%;
    padding-right: 20px;
  }
}
