.box {
    text-align: center;
    background-color: rgba(255,255,255,0.8);
    // padding: 4rem 3rem;
    border-radius: 2px;
    box-shadow: 0 1.5rem 4rem rgba(0,0,0,.5); 
    transition: transform .5s ease-in-out;
    height: 310px;
    overflow-y: auto;
    .icon {
      color: transparent;
      font-size: 4rem;
      margin-bottom: 2rem;
      background-image: linear-gradient(to right, rgba(255, 75, 31, .7), rgba(255,144,104, 0.7));
    }
  
    h2 {
      margin-bottom: 1.5rem;
      font-size: 1.7rem;
      font-family: 'Oswald', sans-serif;
    }
  
    p {
      letter-spacing: 1.2px;
    }
  
    &:hover {
      transform: translatey(-7px) scale(1.03);
  
    }
  
  }
  h1 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
    margin-bottom: 32px;
  }
  
  .cards {
    display: grid;
    grid-gap: 32px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
  }
  
  .card {
    width: 200px;
    height: 300px;
    margin: auto;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    border: 2px solid #928c8c;
    background-color: rgba(255, 255, 255, 0.75);
    background: linear-gradient(45deg, rgba(255,255,255,0.35) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 100%);
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    border-image: linear-gradient(220deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 100%);
  }
   .card:hover{
      transform: scale(1.008) translateY(-5px);
      box-shadow: 0 8px 20px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.16);
   }
   .card-desc {
    line-height: 1.6;
    color: #636b6f;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .card-subtitle{
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
  }
  .card-content {
    text-align:  left;
    padding-left: 10px;
  }
  .card-jobtitle {
    text-transform: uppercase;
    color: grey; 
    letter-spacing: 1px;
    font-size: 14px;
    margin-top: -10px;
  }
    .image-voyager{
      height: 220px;
      width: 100%;
      background-size: cover;
      background-position: 50% 70%; 
    }
  
    /* to center an image */
    /* margin-left: auto;
    margin-right: auto; */
  
   @media(min-width: 660px){
    .image-voyager{
      height: 220px;
      width: 100%;
      object-fit: cover;
      display: flex;
      align-items: center;
      background-size: cover;
  
      background-position: 50% 70%; 
    }
    .card {
      width: 340px;
      margin: auto;
      overflow-y: auto;
      position: relative;
      z-index: 1;
      overflow-x: hidden;
      border: 2px solid #928c8c;
      display: flex;
      transition: 0.3s;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.75);
      background: linear-gradient(45deg, rgba(255,255,255,0.35) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 100%);
      border: 1px solid #fff;
      padding: 10px;
      border-radius: 10px;
      border-image: linear-gradient(220deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 100%);
    }
  }
  
  /* BUTTONS */
    .card-buttons {
      display: flex;
      flex-direction: row;
      background-color: #fff;
      margin-top: auto;
      /* position: sticky; */
      bottom: 0;
      left: 0;
    }
    button {
      flex: 1 1 auto;
      user-select: none;
      background: 0;
      font-size: 13px;
      border: 0;
      padding: 15px 5px;
      cursor: pointer;
      color: #5c5c6d;
      transition: 0.3s;
      font-family: "Jost", sans-serif;
      font-weight: 500;
      outline: 0;
      border-bottom: 3px solid transparent;
    }
  button:hover{
        color: #2b2c48;
        border-bottom: 3px solid #8a84ff;
        background: linear-gradient(
          to bottom,
          rgba(127, 199, 231, 0) 0%,
          rgba(207, 204, 255, 0.2) 44%,
          rgba(211, 226, 255, 0.4) 100%
        );
  }
  