@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

:root {
  --bg-gradient: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
}


footer {
    display: flex;
    width: 400px;
    margin: auto;
    text-align: center;
    background: var(--bg-gradient);   
    opacity: 0.80;
    margin-bottom: 0;
    height: 30vh;
    width: 100%;
    text-align: center;
    padding: 5px 0 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
    footer a  {
    color: #fff;
  }

  .mesh-footer {
    background: url(/images/mesh-profile.png);
    background-size: cover;
    z-index: -1;
    margin-top: 10vh;
    display: flex; 
    flex-direction: column;
    padding-top: 5vh;
  }
// for the gradient footer
  @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.gradient-connect {
  font-family: 'Pacifico', cursive;
  background-image: linear-gradient(90deg, rgba(94,114,235,1) 0%, rgba(255,145,144,1) 56%, rgba(254,193,149,1) 100%);
  color: transparent;
  -webkit-background-clip: text;
  animation: move 1s infinite;
}

@keyframes move {
  50% {
    background-image: linear-gradient(262deg, rgba(94,114,235,1) 0%, rgba(255,145,144,1) 56%, rgba(254,193,149,1) 100%);
  }
}
  // phone
  /* Style the Un-order list by setting its list-style to none */
  .wrapper ul {
    list-style: none;
    width: 90vw;
  }
  .wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wrapper ul li {
    width: 12vw;
    height: 8vh;
    line-height: 75px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    float: left;
    transition: all 0.5s ease;
  }

  /* Style the list items inside the UL list, by setting its width, height and line-height 
    and float them to left and set its border and border-radius.
   */


  /* Style the icons by setting its color and margin-top value to 20px 
  to align it properly */
  .wrapper ul li .fa {
    color: grey;
    // margin-top: 20px;
    transition: all 0.5s ease;
  }

  /* Now target the specific li classes for styling and use box-shadow effect to border and text-shadow effect
    to icons for glowing effect and use transition property for smooth transition effect. */
  /*facebook*/
  .wrapper ul li:hover.facebook {
    border: 5px solid #3b5998;
    box-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }

  .wrapper ul li:hover .fa-facebook {
    color: #3b5998;
    text-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }

  /*twitter*/
  .wrapper ul li:hover.twitter {
    border: 5px solid #00aced;
    box-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }

  .wrapper ul li:hover .fa-twitter {
    color: #00aced;
    text-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }

  /* instagram */
  .wrapper ul li:hover.instagram {
    border: 5px solid #bc2a8d;
    box-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }

  .wrapper ul li:hover .fa-instagram {
    color: #bc2a8d;
    text-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }

  /* google */
  .wrapper ul li:hover.google {
    border: 5px solid #dd4b39;
    box-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }

  .wrapper ul li:hover .fa-google {
    color: #dd4b39;
    text-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }

  /* whatsapp */
  .wrapper ul li:hover.whatsapp {
    border: 5px solid #4dc247;
    box-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }

  .wrapper ul li:hover .fa-whatsapp {
    color: #4dc247;
    text-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }

// PHONE
  @media screen and (min-width: 750px){
    .wrapper {
      width: 100vw;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
    .wrapper ul li {
      width: 75px;
      height: 75px;
      line-height: 75px;
      margin: 0 10px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      border: 5px solid grey;
      float: left;
      transition: all 0.5s ease;
    }
    .copy-right{
      display: flex; 
      flex-direction: column;
    }
  }

