/*
Default Style
============================*/
// MEDIA QUERY MANAGER
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components
*/

$body-font-family: 'Open Sans', sans-serif;
$heading-font-family: 'Dosis', sans-serif;

$main-color: white; 
$body-color: #4d4d4d;
$heading-color: #212121;
$white-color: #ffffff;
$black-color: #000000;
$box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
$transition: all 0.6s ease;
$border-radius: 4px;

body {
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 1.8;
    font-family: $body-font-family;
	color: $body-color;
}
// a {
// 	display: inline-block;
//     transition: $transition;
//     text-decoration: none;
// 	&:hover {
//         text-decoration: none;
//         color: $heading-color;
// 	}
// 	&:focus {
// 		text-decoration: none;
// 	} 
// }
// button {
//     outline: 0 !important; 
// 	box-shadow: none;
// 	border: none;
// 	background-color: transparent;
//     &:focus {
//         box-shadow: none;
//     }
// }
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
	font-family: $heading-font-family;
	font-weight: 700;
}
h3 {
	font-size: 22px;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: 15px;
	margin-bottom: 15px;
	line-height: 1.8;
	&:last-child {
        margin-bottom: 0;
    }
} 
img {
    max-width: 100%;
    height: auto;
}

/*
Preloader Area Style
======================================================*/
.preloader-deactivate {
    display: none;
  }
  
  .preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #04063c;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  
  .spinner {
    height: 40px;
    font-size: 10px;
    text-align: center;
  }
  
  .spinner > div {
    background-color: #fff;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
  