.nav-header{
    position: sticky;
    position: fixed;
    right: 0;
    z-index: 600;
    padding: 10px;
}
#sideNav > h2 > a:hover {
    transition: all 0.5s ease;
    filter: drop-shadow(2px 4px 8px #585858);
}
#sideNav > h2 > a:hover {
    color: rgba(94,114,235,1);
}
.hamburger{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
/* General styles for the lines */
.hamburger .line{
    display: block;
    width: 40px;
    height: 3px;
    background-color: #D9AFD9;
    background-image: linear-gradient(20deg, #D9AFD9 9%, #97D9E1 55%);
      
    margin-block: 10px; /*Adds separation to the bottom and the bottom*/
    border-radius: 4px;
    transition: transform .5s,
    opacity .25s;
}
/* Selecting the lines individually to add different effects when the active class is added to the hamburger menu.*/
.hamburger.active .line:nth-child(1){
    transform: translateY(13px)
    rotate(45deg); /*Moves down this line 13px and rotates it 45 degrees to create the X*/
}
.hamburger.active .line:nth-child(2){
    opacity: 0; /*Hides this line.*/
}
.hamburger.active .line:nth-child(3){
    transform: translateY(-13px)
    rotate(-45deg); /*Moves up this line 13px and rotates it -45 degrees to create the X*/
}  

.hamburger.active{
    position: fixed;
    right: 0;
    color: purple;
}
.hamburger.active .line{
    color: purple;
}

.container-sidenav {
    display: flex;
    justify-content: space-between;
    // column-gap: 80px;
}
#sideNav {
    display: flex;
    flex-direction: column;
    //   margin: auto;
      padding: 10rem;
      background-color: beige;
  width: 100%;
  height: 100vh;
  transition: .45s ease;
  padding: 8rem 6rem;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
} 



