@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}
body {
  min-height: 100vh;
  font-family: "lato", sans-serif;
  overflow-X: hidden;
}

.view-all-button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.collection{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
// SINGLE SNACK
.product-image img{
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 300px;
}

// SPAN HEADER

  .section-head {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  transform:skew(0deg, 6deg);
  padding-top: 6vh;
  }

  .section-head h4 {
  position: relative;
  padding:0;
  color:#f91942;
  line-height: 1;
  letter-spacing:0.3px;
  font-size: 34px;
  font-weight: 700;  
  text-align:center;
  text-transform:none;
  margin-bottom:30px;
  }
  .section-head h4:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right:0;  
  margin:0 auto;
  }
  .section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color:#2f2f2f
  }

  .parent {
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    // transform:skew(0deg, -6deg);
    transform: skewY(-5deg);  
    padding-bottom: -50px;
    margin-top: 100px;
    width: 100vw;
    height: 80vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: flex;
  }
  .project-container{
    transform:skew(0deg, 6deg);
  }
  .mesh-background {
    padding-top: 100px;
    padding-bottom: 50px;
    margin-top: -25vh;
    background: url(/images/mesh-profile.png);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
  .parent > .mesh-background {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;
  }
  .outer-container {
    display: flex;
    flex-wrap: wrap;
    // margin: auto;
    padding-bottom: 3rem;
    max-width: 93.5rem;
    transform:skew(0deg, 6deg);
  }